<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">WhatsApp Estatus</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="initialize"
              small
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  label="Buscar"
                  dense
                  filled
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
  				  <v-data-table
  				    :headers="headers"
  				    :items="usuarios"
  				    class="elevation-0"
              :search="search"
  				  >

  				    <template v-slot:item.actions="{ item }">
                <v-chip small @click="editItem(item)" color="primary" dark> Ver </v-chip>
  				    </template>

  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>
	  </v-row>


	  <!-- Agregar un nuevo registro -->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"> Ver estatus </span>
        </v-card-title>

        <v-card-text>

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
            v-if="cargar"
          ></v-progress-linear>

        	<span v-if="estatusWhats == 1 " class="text-h4"><v-icon left color="green">mdi-circle</v-icon> CONECTADO </span>
        	<span v-if="estatusWhats == 2 " class="text-h4"><v-icon left color="orange">mdi-circle</v-icon> NO ACTIVA </span>
        	<span v-if="estatusWhats == 3 " class="text-h4"><v-icon left color="red">mdi-circle</v-icon> NO EXISTE SESIÓN </span>

        	<canvas id="canvas"></canvas>


        	<v-autocomplete
            filled
            dense
            clearable
            v-model="usuario"
            :items="usuarios"
            label="Selecciona un usuario"
            item-text="nombre_completo"
            item-value="whatsappservidor"
            class="mt-4"
            return-object
          >
          </v-autocomplete>
        	
        	<v-text-field
        		v-if="usuario"
        	  label="WhatsApp"
        	  v-model="usuario.whatsappservidor"
        	  filled
        	  dense
        	></v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="info"
            tile
            class="elevation-6"
            dark
            @click="validarEstatus()"
          >
            Estatus
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            tile
            class="elevation-6"
            dark
            @click="escanerQR()"
          >
            Inicializar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-3"
            tile
            class="elevation-6"
            dark
            @click="cerrarSesion()"
          >
            Cerrar sesión
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  import { io }         from "socket.io-client";

  var QRCode = require('qrcode')

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Usuairo'    , value: 'nombre_completo' },
        { text: 'Plantel'    , value: 'plantel' },
        { text: 'WhatsApp'   , value: 'whatsappservidor' },
        { text: 'Puesto'     , value: 'puesto' },
        { text: 'actions'    , value: 'actions' },
      ],
      videos: [],
      editedIndex: -1,

      search:'',

      telefono:'',

      estatusWhats: 0,
      usuarios:[],
			mensajes:[],
			usuario: null,
      socket:null,
    }),

    async created () {
      await this.initialize()
    },

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    mounted() {

      // Modo servidor
      // this.socket = io(`https://whaencinbi.club/`, {
      //   transports: ["websocket"], path: '/ws/socket.io' 
      // });

      // Modo local
      this.socket = io(`ws://localhost:3005/`, {
        transports: ["websocket"], path: '/ws/socket.io' 
      });

      this.socket.on("connect", ( ) => {
        // Conectar cocket
        console.log('estoy conectado al socket, enserio')

        // Mandar a decirle quién se conecto
        this.socket.emit('iniciarSesionWha', this.getdatosUsuario )
      });

      this.socket.on("disconnect", () => {
        console.log('Desconectado del socket');
      });

      this.socket.on('obtenerContactos', ( contactos ) => {

        this.conversaciones = contactos

        this.loader = false
      })


      /*
        Nueva asignación:
        -. Cuando se realiza una nueva asignación, el socket, debe escucharlo y cargar a los usuarios a los que tiene permiso de revisar
        -. También se debe poder mostrar el chat, entonces, son dos cosas las que se deben realizar
      */

      this.socket.on("nuevaAsignacion", () => {

        // Obtenemos los whatsapp de los que tenemos permiso recibir
        this.getAsignados( this.wha )

        // Obtener todos los chats
        this.getChats(  )
      });

      this.socket.on("mensajesCreados", ( mensaje ) => {

        // Desestructurar datos
        const { fromMe, froms, message } = mensaje

        // Validar que no sea mio 
        if( !fromMe ){  

          // Buscar si el que mandó mensaje es de los que tengo acceso
          if( this.asignados.find( el => froms.match( el ) ) ){

            // Si es de los míos, sonar la campana de mensajes
            this.sonido.play()
          }

        }
        
        // Validar que el mensaje que llégó, sea el que esta abierto, si es así, hay que agregarlo al chat para que se refleje
        if( froms.match( this.chat.id.user ) || message.to.match( this.chat.id.user ) ){
          console.log('oihonh')
          this.verChat( this.chat )
        }

        // Obtener todos los chats
        this.getChats(  )
      });

      this.socket.on('QR-generado', ( body ) => {

        QRCode.toCanvas(document.getElementById('canvas'), body.qr  , (error) => {
          if (error) console.error(error)
          console.log('success!');
          this.cargar = false
        })
      })


      this.socket.on('session-activa', ( ) => {

        this.estatusWhats = 1
      
      })


      this.socket.on('session-inactiva', ( body ) => {

        this.estatusWhats = 2
        
      })
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.usuarios = []
        this.mensajes = []
        return this.$http.get('whatsapp.usuarios').then(response=>{
        	this.usuarios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validarEstatus () {
      	if( !this.usuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

        this.cargar = true
      	this.dialog = false

        const payload = {
        	from: this.usuario.whatsappservidor
        }

        this.url_servidor = this.usuario.url_servidor + 'whatsapp.activo'

        return this.$http.post(this.url_servidor, payload).then(response=>{
        	if( response.data == 'CONNECTED' ){
        		this.estatusWhats = 1
        	}else{
        		this.estatusWhats = 2
        	}
        	this.cargar = false
        	this.dialog = true
        }).catch( error =>{
        	this.estatusWhats   = 3
        	this.dialog         = true
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

			escanerQR () {
      	if( !this.usuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }

        const payload = {
        	from: this.usuario.whatsappservidor
        }
        

        this.url_servidor = this.usuario.url_servidor + 'whatsapp.inicializar'
        return this.$http.post(this.url_servidor, payload).then(response=>{
          this.validarEstatus()
        }).catch( error =>{
          this.validarEstatus()
        	this.dialog = true
          this.validarError( error.response.data.message )
        })
      },

			cerrarSesion () {
      	if( !this.usuario.whatsappservidor ){ return this.validarErrorDirecto('FAVOR DE INGRESAR UN TELÉFONO') }
        this.cargar = true
      	this.dialog = false

        const payload = {
        	from: this.usuario.whatsappservidor
        }

        this.url_servidor = this.usuario.url_servidor + 'whatsapp.destruir'
        return this.$http.post(this.url_servidor, payload).then(response=>{
        	this.estatusWhats = 2
        	this.cargar = false
        	this.dialog = true
        }).catch( error =>{
        	this.dialog = true
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem( item ){
        this.usuario = item
        this.dialog = true
      }


    },
  }
  /*454*/
</script>